.before-launch {
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  padding: 0 0 32px;

  @mixin sm {
    padding: 0 0 48px;
  }

  @mixin md {
    background-color: #F9F9FA;
    flex-direction: row;
    padding: 0;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;

    @mixin sm {
      max-width: 683px;
      padding: 0 0 66px;
    }

    @mixin md {
      max-width: 1129px;
      flex-direction: row;
      padding: 0px 75px;
    }

    @mixin lg {
      max-width: 1440px;
      padding: 0px 52px;
    }
  }
}

.gift-certificates {
    background: #F7F7F7;
    padding: 60px 0;
    @mixin sm {
        padding: 100px 0;
    }

    .container {
        @mixin container-responsive;
    }

    .content {
        
    }
}
.product-purchase {
  display: flex;
  flex: 1;
  background: #FFFFFF;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 0;
  overflow: hidden;

  @mixin sm {
    padding: 20px 16px 0px;
  }

  @mixin md {
    padding: 108px 36px 83px 20px;
    align-items: flex-start;
    background: #F9F9FA;
  }

  @mixin lg {
    padding: 108px 100px 83px 40px;
    align-items: flex-start;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 510px;
    gap: 16px;

    @mixin md {
      display: block;
    }

    @mixin xl {
      max-width: 586px;
    }

    p {
      @mixin rudBody1Regular;
      margin: 0;
    }
  }

  .header {

    .title2 {
      @mixin rudH2;
      display: inline-flex;
      margin-bottom: 9px;
      color: #7BC4AB;
      text-transform: uppercase;

      @mixin md {
        margin-bottom: 20px;
      }
    }

    h1 {
      @mixin rudH2;
      margin: 0;
      text-transform: none;

      @mixin md {
        @mixin rudH1;
      }
    }

    .sub-header{
      align-items: center;
      display: flex;
      padding: 11px 0 0;
      gap: 7px;

      @mixin md {
        padding: 8px 0;
      }

      span,
      a {
        @mixin rudBody1Regular;
      }
    }
  }

  .subscription-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 0;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    flex-wrap: wrap;
    gap: 24px;

    @mixin md {
      margin-bottom: -8px;
      margin-top: -20px;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }

    strong {
      @mixin rudBody1Bold;
    }

    span {
      @mixin rudBody1Regular;
    }
  }

  .price-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .paragraph {
    @mixin rudBody1Regular;

    @mixin md {
      margin: 32px 0;
    }
    color: #323232;
  }

  .notes {
    display: flex;
    gap: 8px;
    flex-direction: column;

    @mixin md {
      margin: 29px 0 24px;
    }

    .note {
      display: inline-flex;
      align-items: flex-start;

      strong {
        @mixin rudBody1Medium;
      }

      .img {
        width: 26px;
        display: flex;
        align-items: center;
        position: relative;
        top: 4px;
      }
    }
  }

  .sub-notes {
    @mixin rudBody1Regular;
    color: #918E8E;
  }

  hr {
    height: 1px;
    border: none;
    background: #E6E6E6;
    margin: 0;
  }

  .joinSection {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 40px 20px 35px 20px;
    margin-bottom: 45px;
  }
  
  .emailWrapper {
    display: flex;
    margin-bottom: 20px;
  }
  
  .emailInput {
    margin-right: 7px;
    flex: 1;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 10px 11px;
  }

  .terms {
    color: #323232;
    font-size: 15px;
    line-height: 22px;
    a {
      color: #323232;
      text-decoration: underline;
    }
  }
}

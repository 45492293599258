.container {
  @mixin flex-center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #FFFFFF;

  img {
    max-width: 90px;
    @mixin desktop {
      max-width: 120px;
    }
  }
}

.price-select {
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  background-color: #EFF9F480;
  border: 1px solid #7BC4AB80;
  border-radius: 4px;
  width: 100%;
  position: relative;
  padding: 22px 24px 22px 26px;

  &.with-bubble {
    padding-top: 28px;
    padding-bottom: 25px;
    margin-top: 13px;

    @mixin md {
      padding: 26px 24px 17px 31px;
    }
  }

  .texts {
    display: flex;
    flex-direction: column;
    flex: 1;

    .title {
      display: flex;
      justify-content: space-between;

      @mixin md {
        height: 25px;
      }

      .price-details {
        display: inline;
        position: relative;

        .old-price {
          @mixin rudBody1Bold;
          display: inline-flex;
          font-size: 14px;
          line-height: 16px;
          color: #918E8E;
          text-decoration: line-through;
        }

        .price {
          @mixin rudBody1Bold;
          margin-left: 4px;
          color: #908E8E;
        }
      }
    }

    p {
      @mixin rudBody1Regular;
      color: #323232;
      margin: 8px 0 0;

      @mixin md {
        margin-top: 7px;
      }

      &.details {


      }

      &.offer-details {
        margin-top: 0px;
        color: #918E8E;
      }
    }
  }

  .h3 {
    text-transform: none;
    @mixin rudBody1Bold;
    margin: 0;
    color: #918E8E;

    @mixin md {
      @mixin rudH3;
    }
  }

  .bubble-text {
    position: absolute;
    top: -14px;
    left: 26px;
    height: 27px;
    border-radius: 44px;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.025em;
    padding: 0 20px;
    background: #7BC4AB;
    display: flex;
    align-items: center;
  }


  &.selected {
    .h3 {
      color: var(--color-black);
    }

    .price {
      color: #7BC4AB!important;
    }
  }
}

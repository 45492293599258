.product-image-slider {
  background: #f3f3f4;
  width: 100%;

  @mixin md {
    width: 40%;
    max-width: 551px;
  }

  @mixin lg {
    height: 700px;
  }

  @mixin xl {
    max-width: 750px;
  }

  .photo-wrap {
    min-height: 100%;

    .photo {
      display: flex;
      justify-content: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    img {
      object-fit: cover;

      @mixin md {
        width: auto;
      }
    }
  }

  .prevArrow,
  .nextArrow {
    height: 20px;
    width: 20px;
    position: absolute;
    bottom: 50%;
    margin-bottom: -10px;
    z-index: 1;
    cursor: pointer;

    &:hover {
      svg {
        fill: #7BC4AB;
      }
    }

    svg {
      fill: #908E8E;
      height: 100%;
      width: auto;
    }
  }

  .prevArrow {
    left: 20px;
  }

  .nextArrow {
    right: 20px;
  }

  :global(.slick-slider) {
    height: 100%;
    position: relative;
  }

  :global(.slick-slide) {
    border: none;
    margin: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }


  :global(.slick-dots) {
    position: absolute;
    z-index: 1;
    bottom: 30px;

    li {
      margin: 0;

      &:global(.slick-active) {
        button:before {
          color: #7BC4AB;
        }
      }

      button:before {
        font-size: 9px;
        color: #D9D9D9;
      }
    }
  }
}

.product-image-slider,
.photo {
  height: 100vw;

  @mixin md {
    height: 946px;
  }

  @media (min-width: 1111px) {
  }
}

.dropdown.variant-common{
  nav {
    gap: 38px;
  }
}

.dropdown.variant-dashboard{
  z-index: 3;
  min-width: unset;
  width: 102px;
  top: 39px;
  border-radius: 8px;
  nav {
    gap: 5px;
  }
  &.open {
    padding: 10px 20px;
  }
  &.from-right{
    right: -13px;
  }
}
.dropdown-button.variant-dashboard {
  width: 100%;
}



/* shared styles */
.dropdown-button {
    display: inline-block;
    position: relative;
}

.button-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    @mixin rudBody1Bold;
}

.dropdown {
    border-radius: 24px;
    overflow: hidden;
    background: var(--color-white);
    position: absolute;
    left: 0;
    top: 44px;
    min-width: 100%;
    max-width: 500%;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 0px 20px rgba(0,0,0,.13);

    nav {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        gap: 38px;
    }

    &.from-right{
      left: unset;
      right: 0;
    }

    &.closed {
        max-height: 0px;
        padding: 0 20px 0 20px;
        pointer-events: none;
    }

    &.open {
        max-height: 1000px;
        padding: 31px;
    }
}

.space {
  height: 5px;
  position: absolute;
  left: 0;
  width: 100%;

}

.custom-button {

}
